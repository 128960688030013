
.banner {
    background-image: url("../../assets/v2-assets/v2-Landing-Panel3-Hands.png");
    /* background-image: url("../../assets//images/Ethos-Sustainability-Image.jpeg"); */

    background-position:center top;
    background-repeat: no-repeat;
    background-size: cover;
    position:relative;
    padding: 300px 0px 700px 0px;
}

.banner-text {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #20263d;
}

@media screen and (max-width: 692px) {
    .banner {
        display:none;
    }
}