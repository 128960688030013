
.button-color {
    background-color: #0f092b;
}

.socials-nav {
    margin-right: 20px;
}

.banner-image {
    padding-left:22px;
}
@media screen and (max-width: 991px) {
    .hide-socials {
        display: none;
    }
    .mobile{
        justify-content: center;
    } 
}


