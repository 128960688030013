.footer {
    padding-bottom: 50px
}

.footer img {
    width: 240px;
}

.not-needed-links {
    display: none;
}