.column {
    width: 500px;
    
}

.contact-form {
    background-color: rgb(203, 206, 159, 0.43);
    text-align: left;
    color: #485136;
    box-shadow: 32px 32px 16px rgb(52, 53, 52, 0.15);
    
}


.contact-form form{
    padding: 10px 40px 40px 40px;
}

.contact-form img{
    width: 100%;
}
.contact-form h2 {
    text-align:center;
}
.contact-form input,
.contact-form textarea {
    background-color: rgb(240, 243, 223, 0.6);
    color: #485136;
    
}
.contact-form label {
    margin-top: 10px;
}

.button-color {
    color: #FBFCFA;
    
}

@media screen and (max-width: 1200px) {
    .column {
        
        width: 100%
    }
    
}
