.container-color {
    background-color: #f8faee;
}


/*--------------- TITLE -----------------*/
.ethos-title {
    padding: 150px 10px 50px 10px;
}
.ethos-title img {
    width: auto;
    height: 250px;
    border-radius: 50%;
    box-shadow: 10px 10px 20px 1px rgba(52,53,52,0.6);
}
/* -------------------------------------- */


/*--------------- ETHOS IMAGES -----------*/

.ethos-text img {
    width: auto;
    height: 300px;
    padding-left: 10px;
    padding-right: 10px;
}

.wing-left img {
    left: 250px;
}
.wing-right img {  
    right: 170px;
}

/* ---------------------------------------- */


/* ---------------MEDIA QUERIES------------ */


@media screen  and (max-width: 1670px) {
    .wing-left img {
        
        left: 100px;
    }
    .wing-right img {
        
        right: 100px;
    }
}

@media screen  and (max-width: 1300px) {
    .ethos-text img {
        height: auto;
        width: 22vw;
    }    
}
@media screen and (max-width: 991px) {
    .ethos-title img  {
        width: 40vw;
        height: auto;
    }
    .ethos-text img {
        height: auto;
        width: 70vw;
    }
    .ethos-text {
        padding: 15px 0px 15px 0px;
    }
    .ethos-title {
        padding: 70px 10px 50px 10px;
    }
}
@media screen and (min-width: 992px ) {
    .wing-images img {
        position: relative;
        top: -250px;
    }
    .ethos-title {
        padding-top: 150px 10px 50px 10px;
    }
}

@media screen and (max-width: 692px) {
    .mobile-spacing-div {
        padding-top: 80px;
    }
}
/* ------------------------------------------- */